var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buyOrderParticulars" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "买料详情",
          "left-arrow": "",
          fixed: "",
          "safe-area-inset-top": "",
          placeholder: ""
        },
        on: { "click-left": _vm.leftClick }
      }),
      _vm.orderList.status == "SUSPENSE"
        ? _c("div", { staticClass: "reviewProgress  ToBeReviewed" })
        : _vm._e(),
      _vm.orderList.status == "WAIT_FETCH_AUDIT" ||
      _vm.orderList.status == "WAIT_FETCH" ||
      _vm.orderList.status == "FAIL"
        ? _c("div", { staticClass: "reviewProgress  FailAndReclaiming" })
        : _vm._e(),
      _vm.orderList.status == "WAIT_FETCH_FINISHED"
        ? _c("div", { staticClass: "reviewProgress reclaiming" })
        : _vm._e(),
      _vm.orderList.status == "DONE"
        ? _c("div", { staticClass: "reviewProgress  Completed" })
        : _vm._e(),
      _c("div", { staticClass: "orderInfo" }, [
        _c("div", { staticClass: "orderTop title" }, [
          _c("h3", [_vm._v(" 订单信息")]),
          _c(
            "h3",
            {
              style: {
                color:
                  _vm.orderList.status == "WAIT_FETCH" ||
                  _vm.orderList.status == "WAIT_FETCH_AUDIT" ||
                  _vm.orderList.status == "SUSPENSE" ||
                  _vm.orderList.status == "UNDELIVERED"
                    ? "#ff2d2e"
                    : _vm.orderList.status == "WAIT_FETCH_FINISHED"
                    ? "#02790D"
                    : _vm.orderList.status == "WAIT_PAYED"
                    ? "#FF2D2E"
                    : "#999999"
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.orderList.status == "WAIT_FETCH_AUDIT"
                    ? "取料审核中..."
                    : _vm.orderList.status == "WAIT_FETCH"
                    ? "待取料"
                    : _vm.orderList.status == "FAIL"
                    ? "未通过"
                    : _vm.orderList.status == "SUSPENSE"
                    ? "待审核"
                    : _vm.orderList.status == "WAIT_FETCH_FINISHED"
                    ? "取料中"
                    : _vm.orderList.status == "WAIT_PAYED"
                    ? "待付款"
                    : _vm.orderList.status == "UNDELIVERED"
                    ? "未交付"
                    : _vm.orderList.status == "RECEIVE"
                    ? "已交付"
                    : _vm.orderList.status == "CANCEL"
                    ? "已取消"
                    : "已完成"
                )
              )
            ]
          )
        ]),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("下单时间")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm._f("formatDate_")(_vm.orderList.createTime)))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("订单编号")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.orderList.oid))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("货物分类")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.orderList.goods[0].name))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("单价(元/克)")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.orderList.goods[0].price))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("重量(克)")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.orderList.goods[0].weight.toFixed(2)))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("押金比例(%)")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.show ? _vm.depositProportion : "-"))
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "leftText" }, [_vm._v("金额(元)")]),
            _c("div", { staticClass: "rightText" }, [
              _vm._v(_vm._s(_vm.orderList.goods[0].total))
            ])
          ])
        ])
      ]),
      (_vm.orderList.status == "FAIL" ||
      _vm.orderList.status == "DONE" ||
      _vm.orderList.businessType == "1"
      ? false
      : true)
        ? _c("div", { staticClass: "getMoney" }, [
            _c("div", { staticClass: "title" }, [
              _c("h3", [_vm._v(" " + _vm._s(_vm.GLOBAL) + "收款账号")]),
              _c(
                "div",
                {
                  staticClass: "copy",
                  on: {
                    click: function($event) {
                      return _vm.copy(_vm.banklist.num)
                    }
                  }
                },
                [_c("div", { staticClass: "icon" }), _vm._v(" 复制")]
              )
            ]),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "bankIcon moneyflex" }, [
                _c("div", { staticClass: "leftText" }, [_vm._v("收款银行")]),
                _c("div", { staticClass: "bank" }, [
                  _vm._v(_vm._s(_vm.banklist.bank))
                ])
              ]),
              _c("div", { staticClass: "moneyflex" }, [
                _c("div", { staticClass: "leftText" }, [_vm._v("银行卡号")]),
                _c("div", { staticClass: "rightText" }, [
                  _vm._v(_vm._s(_vm.banklist.num))
                ])
              ]),
              _c("div", { staticClass: "moneyflex" }, [
                _c("div", { staticClass: "leftText" }, [_vm._v("开户支行")]),
                _c("div", { staticClass: "rightText" }, [
                  _vm._v(_vm._s(_vm.banklist.address))
                ])
              ]),
              _c("div", { staticClass: "moneyflex" }, [
                _c("div", { staticClass: "leftText" }, [_vm._v("持卡人")]),
                _c("div", { staticClass: "rightText" }, [
                  _vm._v(_vm._s(_vm.banklist.name))
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.orderList.businessType == "1" &&
      (_vm.orderList.status == "BUY_VIOLATE" ||
        _vm.orderList.status == "WAIT_FETCH_AUDIT")
        ? _c("div", { staticClass: "settlementSheet" }, [
            _c("h3", { staticClass: "jieSuan" }, [_vm._v("结算单")]),
            _c(
              "div",
              {
                staticClass: "xiangQing",
                on: { click: _vm.ToPurchaseSettlement }
              },
              [_vm._v(" 详情 "), _c("div", { staticClass: "rightIcon" })]
            )
          ])
        : _vm._e(),
      _vm.isDefault
        ? _c(
            "div",
            { staticClass: "buyDefault", on: { click: _vm.toBuyDefault } },
            [_vm._v("无法提货怎么办?")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }